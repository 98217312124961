import { Deliverable, Order, OrderFlow, OrderScope } from 'utils/types/orders';
import { EditOrderProps } from 'pages/Orders/Orders/types';
import { Button } from 'components/Button/Button';
import DealInformation from 'pages/Orders/Orders/Order/components/OrderDetails/components/DealInformation';
import ExpectedDeliverables from 'pages/Orders/Orders/Order/components/OrderDetails/components/ExpectedDeliverables/ExpectedDeliverables';
import ActualDeliverables from 'pages/Orders/Orders/Order/components/OrderDetails/components/ActualDeliverables/ActualDeliverables';
import ProductInformation from 'pages/Orders/Orders/Order/components/OrderDetails/components/ProductInformation';
import SettlementInformation from 'pages/Orders/Orders/Order/components/OrderDetails/components/SettlmentInformation';
import Terms from 'pages/Orders/Orders/Order/components/OrderDetails/components/Terms';
import WalletsInformation from 'pages/Orders/Orders/Order/components/OrderDetails/components/WalletsInformation';
import { ProductType } from 'utils/types/product';
import { Box, Stack } from '@mui/material';
import { Card } from 'components/Card/Card';
import { ButtonDropdown } from 'components/Button/ButtonDropdown';
import { ButtonDropdownItem } from 'components/Button/ButtonDropdownItem';
import { ReactComponent as ArrowDown } from 'assets/chevron-down.svg';
import TableTitleWithActions from 'components/Table/TableTitleWithActions';
import CustomBasketDeliverables from './components/CustomBasketDeliverables/CustomBasketDeliverables';
import IndexBasketDeliverables from './components/IndexBasketDeliverables/IndexBasketDeliverables';
import { ReactComponent as CustomBasketIcon } from 'assets/custom-basket-deliverables.svg';
import { ReactComponent as IndexBasketIcon } from 'assets/index-basket.svg';
import { ReactComponent as OrderDetailsIcon } from 'assets/order-details.svg';
import { ReactComponent as ExpectedDeliverablesIcon } from 'assets/expected-deliverables.svg';
import { ReactComponent as ActualDeliverablesIcon } from 'assets/actual-deliverables.svg';
import { ReactComponent as InformationIcon } from 'assets/information.svg';
import { ReactComponent as WalletsInformationIcon } from 'assets/wallets-information.svg';
import { ReactComponent as SettlementInformationIcon } from 'assets/settlement-information.svg';
import { ReactComponent as TermsIcon } from 'assets/terms.svg';

interface SingleOrderTableProps extends Omit<EditOrderProps, 'onSubmit' | 'changeOrder' | 'order'> {
  customBasketDeliverables?: Deliverable[];
  productType: ProductType;
  orderFlow: OrderFlow;
  downloadCsv?: () => void;
  downloadPdf?: () => void;
  onSubmit?: () => void;
  order?: Order;
  seedDeliverables?: Deliverable[];
}

const OrderDetails = ({
  customBasketDeliverables,
  loading,
  productType,
  onChangeSeedDeliverable,
  onChangeCustomBasketDeliverable,
  order,
  user,
  downloadCsv,
  downloadPdf,
  seedDeliverables,
  orderFlow,
}: SingleOrderTableProps) => {
  const downloadData = (
    <ButtonDropdown
      anchor={
        <Button variant="tertiary" emphasis="medium" endIcon={<ArrowDown />}>
          Download Data
        </Button>
      }
      id={'downloadData'}
    >
      <ButtonDropdownItem
        id="downloadPDF"
        label="Download PDF"
        onClick={downloadPdf ? downloadPdf : () => {}}
        disabled={!Boolean(order?.documents?.confirmationPdf?.url)}
      />

      <ButtonDropdownItem
        id="downloadCSV"
        label="Download CSV"
        onClick={downloadCsv ? downloadCsv : () => {}}
      />
    </ButtonDropdown>
  );

  return (
    <Stack gap={4}>
      {/* Order Details */}
      <Card
        sx={{ padding: '24px' }}
        header={
          <Box display="flex" justifyContent="space-between">
            <TableTitleWithActions icon={<OrderDetailsIcon />} title="Order Details" />
            {orderFlow === 'confirm' && downloadData}
          </Box>
        }
        headerDivider={false}
        body={
          <DealInformation
            productType={productType}
            user={user}
            order={order}
            orderFlow={orderFlow}
          />
        }
      />
      {/* Custom basket */}
      {orderFlow === 'delegate' &&
        order?.deliveries?.expected &&
        order.deliveries.expected.length > 1 &&
        order?.scope === OrderScope.CUSTOM_BASKET && (
          <Card
            sx={{ padding: '24px' }}
            header={
              <Box display="flex" justifyContent="space-between">
                <TableTitleWithActions icon={<CustomBasketIcon />} title="Custom Basket" />
              </Box>
            }
            headerDivider={false}
            body={
              <CustomBasketDeliverables
                productType={productType}
                onChangeCustomBasketDeliverable={onChangeCustomBasketDeliverable}
                order={order}
                customBasketDeliverables={customBasketDeliverables}
              />
            }
            isLoading={loading}
          />
        )}
      {/* Expected Deliverables */}
      <Card
        sx={{ padding: '24px' }}
        header={
          <TableTitleWithActions
            icon={<ExpectedDeliverablesIcon />}
            title="Expected Deliverables"
          />
        }
        headerDivider={false}
        body={
          <ExpectedDeliverables
            productType={productType}
            onChangeSeedDeliverable={onChangeSeedDeliverable}
            order={order}
            seedDeliverables={seedDeliverables}
          />
        }
      />
      {/* Actual Deliverables */}
      {orderFlow === 'confirm' && ( // Never show Actual Deliverables table on the create (or create delegate) order page
        <Card
          sx={{ padding: '24px' }}
          header={
            <TableTitleWithActions icon={<ActualDeliverablesIcon />} title="Actual Deliverables" />
          }
          headerDivider={false}
          body={<ActualDeliverables productType={productType} order={order} />}
        />
      )}
      {/* Index basket */}
      {order?.scope === OrderScope.CUSTOM_BASKET && (
        <Card
          sx={{ padding: '24px' }}
          header={<TableTitleWithActions icon={<IndexBasketIcon />} title="Index Basket" />}
          headerDivider={false}
          body={<IndexBasketDeliverables productType={productType} order={order} />}
        />
      )}
      {/* Product Information */}
      <Card
        sx={{ padding: '24px' }}
        header={<TableTitleWithActions icon={<InformationIcon />} title="Product Information" />}
        headerDivider={false}
        body={<ProductInformation productType={productType} order={order} />}
      />
      {/* Wallets Information */}
      <Card
        sx={{ padding: '24px' }}
        header={
          <TableTitleWithActions icon={<WalletsInformationIcon />} title="Wallets Information" />
        }
        headerDivider={false}
        body={<WalletsInformation productType={productType} order={order} />}
      />
      {/* Settlement Information */}
      {productType === 'ETP' && (
        <Card
          sx={{ padding: '24px' }}
          header={
            <TableTitleWithActions
              icon={<SettlementInformationIcon />}
              title="Settlement Information"
            />
          }
          headerDivider={false}
          body={<SettlementInformation productType={productType} order={order} />}
        />
      )}
      {/* Terms */}
      <Card
        sx={{ padding: '24px' }}
        header={<TableTitleWithActions icon={<TermsIcon />} title="Terms" />}
        headerDivider={false}
        body={<Terms productType={productType} order={order} user={user} />}
      />
    </Stack>
  );
};

export default OrderDetails;
