import { Button as MuiButton } from '@mui/material';

const butonVariants = ['primary', 'secondary', 'danger', 'tertiary'];

export const Button = ({ ...props }) => {
  if (props.variant && !butonVariants.find((variant) => variant === props.variant)) {
    throw Error('Variant not supported');
  }

  return <MuiButton {...props}>{props.children}</MuiButton>;
};
